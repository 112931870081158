<template>
  <div class="mt-9 text-left" v-if="solarAdmin">
    <div>
      <v-btn :loading="loading" @click="importSolarData()" large color="primary"
        >Import Solar Data <v-icon>mdi-solar-panel</v-icon></v-btn
      >
    </div>
    <div class="log" v-if="log[0]">
      <v-row>
        <v-col cols="12" sm="6">
          <div class="text-left log-container">
            <div class="d-flex justify-space-between">
              <div>
                <h2 class="primary--text">
                  Status:
                  <v-chip small :color="status.color" v-if="status.status">{{
                    status.text
                  }}</v-chip>
                </h2>
              </div>

              <div>
                <v-spacer></v-spacer>
                <span>
                  {{ this.adminImport.completed }}
                  /
                  {{ this.adminImport.count }}
                </span>
              </div>
            </div>
            <div class="mt-1" v-for="(l, index) in log" :key="index">
              {{ l }}
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import parcel from '@/mixins/parcel'
import solar from '@/mixins/solar'
import { parcelRef, solarRef } from '@/firebase/db'
import config from '../../config'
const api = config.api()
export default {
  mixins: [parcel, solar],
  data: () => ({
    statusList: {
      completed: { text: 'Completed', status: 'completed', color: 'success' },
      processing: { text: 'Processing', status: 'processing', color: 'info' },
      error: { text: 'Error', status: 'error', color: 'error' }
    },
    loading: false
  }),
  computed: {
    ...mapGetters({
      solarAdmin: 'isSolarAdmin',
      log: 'solar/log',
      status: 'solar/status',
      adminImport: 'solar/adminImport'
    })
  },
  methods: {
    ...mapMutations({
      setLog: 'solar/setLog',
      setStatus: 'solar/setStatus',
      setAdminImport: 'solar/setAdminImport',
      addLog: 'solar/addLog'
    }),
    getParcelData(number) {
      let fd = {}
      fd.state = ''
      fd.townCity = ''
      fd.number = number
      return fd
    },

    async addSolarData(data, id) {
      if (!id) {
        return
      }
      let solarData = await solarRef.doc(id).get()
      if (solarData.exists) {
        let oldData = this.copyObject(await solarData.data())
        let solarSystems = []

        if (oldData.solar_systems && typeof oldData.solar_systems == 'object') {
          oldData.solar_systems.map(e => {
            solarSystems.push(e)
          })
        }

        data.map(e => {
          let prevData = solarSystems.filter(f => f.bp == e.bp)
          if (prevData[0]) {
            if (!prevData[0].timestampModified) {
              let i = solarSystems.findIndex(f => f.bp == e.bp)
              solarSystems.splice(i, 1, e)
            }
          } else {
            solarSystems.push(e)
          }
        })

        let newData = {
          solar_systems: solarSystems,
          timestampImported: new Date()
        }
        await this.saveSolarData(id, newData)
      } else {
        let solar_data = {
          solar_systems: [...data],
          timestampImported: new Date()
        }
        await this.saveSolarData(id, solar_data)
      }
    },

    async processParcel(parcelData, data) {
      let user = await this.currentUser()
      user = user.uid
      let checkParcel = await parcelRef
        .where('user', '==', user)
        .where('number', '==', parcelData.number)
        .get()
      let parcel
      if (!checkParcel.size) {
        parcel = await this.addParcel({
          ...parcelData,
          timestampImported: new Date()
        })
        parcel = parcel.id
      } else {
        if (checkParcel.docs[0]) {
          parcel = checkParcel.docs[0].id
        }
      }
      await this.addSolarData(data, parcel)
    },

    async processData(data) {
      this.setAdminImport({
        key: 'count',
        value: Object.keys(data.data).length
      })
      this.setStatus(this.statusList.processing)
      for (let parcel in data.data) {
        // let parcelData = this.getParcelData(parcel);
        // await this.processParcel(parcelData, data.data[parcel]);

        // this.addLog(`Imported Parcel ${parcelData.number}`);
        // this.setAdminImport({
        //   key: "completed",
        //   value: this.adminImport.completed + 1,
        // });

        // if (this.adminImport.count == this.adminImport.completed) {
        //   this.setStatus(this.statusList.completed);
        // }

        let parcelData = this.getParcelData(parcel)
        this.processParcel(parcelData, data.data[parcel])
          .then(() => {
            this.addLog(`Imported Parcel ${parcelData.number}`)

            this.setAdminImport({
              key: 'completed',
              value: this.adminImport.completed + 1
            })

            if (this.adminImport.count == this.adminImport.completed) {
              this.setStatus(this.statusList.completed)
              this.updateAllParcelsMetrics()
            }
          })
          .catch(err => {
            console.log(err)
          })
      }
    },
    async updateAllParcelsMetrics() {
      await this.updateMetrics({
        action: 'updateAllParcels'
      })
    },

    async importSolarData() {
      let data
      this.setLog([])
      this.loading = true
      try {
        data = await this.$axios.get(api.solar)
      } catch (err) {
        console.log(err)
      }
      this.loading = false
      if (!data) {
        return
      }

      this.processData(data.data)
    }
  }
}
</script>

<style scoped>
.log-container {
  height: 250px;
  overflow-y: scroll;
}
</style>
